import React, { Component } from 'react';
import { Tag, Input, Tooltip, Icon } from 'antd';

export default class EditableTagGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputVisible: false,
      inputValue: '',
    };
  }

  handleClose = (removedTag, record) => {
    this.props.onClose && this.props.onClose(removedTag, record);
    let value = this.props.value || [];
    value = value.filter(tag => tag !== removedTag);
    this.props.onChange && this.props.onChange(value);
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const state = this.state;
    const inputValue = state.inputValue;
    let { value } = this.props;
    value = value || [];
    if (inputValue && this.props.onConfirm) {
      this.props.onConfirm(inputValue);
    }
    if (inputValue && value.indexOf(inputValue) === -1) {
      value = [...value, inputValue];
    }
    this.setState({
      inputVisible: false,
      inputValue: '',
    });
    this.props.onChange(value);
  };

  saveInputRef = input => {
    this.input = input;
  };

  render() {
    const { inputVisible, inputValue } = this.state;
    const {
      placeholder = '新增',
      disabled,
      addable = true,
      data = [],
      valueName = 'value',
      displayName = 'label',
      typeName = 'type',
    } = this.props;
    const wrongValueArray = this.props.wrongValueArray || [];
    let { value } = this.props;
    value = value || [];
    return (
      <div>
        {value.map(t => {
          let tag = t || '';
          const isLongTag = tag.length > 20;
          const isWrong = wrongValueArray.indexOf(tag) !== -1;
          const target = data.find(d => d[valueName] == t);
          if (target) {
            tag = target[displayName];
          }
          const tagElem = (
            <Tag
              key={t}
              style={isWrong ? { background: 'red', color: 'white' } : {}}
              closable={!disabled}
              className={target ? `tag-type${target[typeName]}` : ''}
              onClose={() => this.handleClose(t, target)}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {addable && !disabled && inputVisible && (
          <Input
            ref={this.saveInputRef}
            type="text"
            size="small"
            style={{ width: 78 }}
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {addable && !disabled && !inputVisible && (
          <Tag
            onClick={this.showInput}
            style={{ background: '#fff', borderStyle: 'dashed' }}
          >
            <Icon type="plus" />
            {placeholder}
          </Tag>
        )}
      </div>
    );
  }
}
